<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      {{ $t("labels.template") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ $t("labels.zns_template_high_rate") }}
      <br />
      {{ $t("labels.zns_template_customize") }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="template in items"
          :key="`t_${template.id}`"
        >
          <div class="text-center mb-2 black--text">
            {{ template.name }}
          </div>
          <div class="mb-1">
            <img
              :src="template.image"
              alt=""
              class="w-100 h-100"
              style="height: 300px"
            />
          </div>
          <v-row dense>
            <v-col cols="12">
              <v-btn
                small
                block
                color="success"
                @click="selectTemplate(template)"
                >{{ $t("labels.select") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ZaloZnsTemplate",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
  }),
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getList() {
      try {
        const { data } = await httpClient.post(
          "/get-zalo-zns-sample-templates"
        );
        this.items = [...data];
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async selectTemplate(template) {
      this.$emit("selectSample", {
        id: this.item.id,
        template,
      });
    },
  },
};
</script>

<style scoped></style>
